@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100..900&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
}

.contact-info {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
  }
  
  a {
    color: #007BFF;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .social-links img {
    transition: transform 0.3s ease;
  }
  
  .social-links img:hover {
    transform: scale(1.1);
  }
  
  .company-address {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
  }
  
  .map-container iframe {
    border: 0;
  }
  