.project-carousel {
  display: flex;
  justify-content: center;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100..900&display=swap');

body {
margin: 0;
padding: 0;
font-family: 'Raleway', sans-serif;
}


.project-card img {
  width: 100%;
  max-width: 400px; /* Definindo um tamanho máximo para a imagem */
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-card {
  text-align: center;
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px; /* Controlando a largura máxima do card */
  margin: 0 auto;
}


.testimonials-grid {
  display: grid;
}

.testimonial-card {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  transition: transform 0.3s ease;
}

.testimonial-card img {
  border-radius: 50%;
}

.testimonial-card:hover {
  transform: translateY(-10px);
}

.developer-services ul {
  list-style: none;
  padding: 0;
}

.developer-services li {
  margin-bottom: 8px;
}

.developer-socials img {
  transition: transform 0.3s ease;
}

.developer-socials img:hover {
  transform: scale(1.1);
}
