@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100..900&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
}

/* Estilera */
.about-title {
  font-weight: 900;
  font-size: 33px;
  font-family: 'Raleway', sans-serif;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

/* Estilo para o texto da página */
.about-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
}

/* Estilo para a assinatura */
.about-signature {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin-top: 40px;
}

/* Estilo para o botão */
.btn-primary {
  display: block;
  width: 52%;
  margin: 20px auto 0;
  padding: 10px;
  background-color: #282929; /* Cor principal */
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #19191a; /* Cor ao passar o mouse */
}
