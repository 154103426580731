.product-card {
  width: 100%;
  border: 1px solid #eaeaea; /* Adiciona uma borda ao redor do card */
  border-radius: 8px; /* Bordas arredondadas */
  overflow: hidden; /* Oculta qualquer conteúdo que ultrapasse o contêiner */
}

.product-image {
  width: 100%; /* Largura total do contêiner */
  height: 240px; /* Altura fixa para a imagem */
  object-fit: cover; /* Ajusta a imagem para cobrir o espaço disponível */
}
